import React from 'react';
import {
	faClock,
	faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INPUT_TYPES } from 'core/constants';
import { getDate } from 'core/services/date';
import {
	isLargerThan,
	isRequired,
	isRequiredHtml,
	isValidUrl,
} from 'core/services/form';

export const entryFormData = (entity, object) => {
	const locations = [
		{
			label: 'Opleidingslokaal PC, Terhagen',
			value: 'Opleidingslokaal PC, Terhagen',
		},
		{
			label: 'Opleidingslokaal Theater, Terhagen',
			value: 'Opleidingslokaal Theater, Terhagen',
		},
		{
			label: 'Vergaderzaal: 1e verdieping, Terhagen',
			value: 'Vergaderzaal: 1e verdieping, Terhagen',
		},
		{
			label: 'vergaderzaal: Orangerie, Terhagen',
			value: 'vergaderzaal: Orangerie, Terhagen',
		},
		{
			label: 'Vergaderzaal: Balzaal, Terhagen',
			value: 'Vergaderzaal: Balzaal, Terhagen',
		},
	];

	return {
		title: {
			type: INPUT_TYPES.TEXT,
			name: 'title',
			label: 'Titel',
			required: true,
			validations: [isRequired],
			value: object?.title ?? object?.name ?? '',
		},
		body: {
			type: INPUT_TYPES.TINY,
			name: 'body',
			label: 'Inhoud',
			description: (
				<span className='alert_comment'>
					<FontAwesomeIcon icon={faTriangleExclamation} />
					<span>
						<b>OPGELET!</b>
						<br />
						De inhoud van dit bericht wordt via KetchUP binnen heel
						UniPartners gedeeld. Deel hier geen gevoelige
						informatie.
					</span>
				</span>
			),
			required: true,
			validations: [isRequiredHtml],
			value: object?.body ?? object?.description ?? '',
		},
		short: {
			type: INPUT_TYPES.TEXTAREA,
			name: 'short',
			label: 'Korte inhoud',
			description:
				'De korte inhoud van dit bericht wordt via de nieuwsbrief binnen heel UniPartners gedeeld. Hou de inhoud kort en interessant.',
			required: true,
			validations: [isRequired],
			value: object?.short ?? '',
		},
		attachments: {
			type: INPUT_TYPES.FILE,
			label: 'Bestanden',
			name: 'attachments',
			accept: '*/*',
			isMulti: true,
			showOptions: true,
			value: [
				...(object?.media?.map(x => ({
					id: x.id,
					filename: x.filename,
					name: x.description,
					attachment: x.type === 'attachment',
					primary: x.primary,
					type: x.mime,
				})) ?? []),
				...(object?.attachments?.map(x => ({
					id: x.id,
					filename: x.filename,
					name: x.description,
					attachment: x.type === 'attachment',
					primary: x.primary,
					type: x.mime,
				})) ?? []),
			],
		},
		links: {
			type: INPUT_TYPES.HIDDEN,
			name: 'links',
			value:
				object?.links?.map(x => ({ name: x.name, url: x.url })) ?? [],
		},
		type: {
			type: INPUT_TYPES.SELECT,
			name: 'type',
			label: 'Type bericht',
			options: [
				{
					label: 'Artikel',
					value: 'news',
				},
				{
					label: 'Opleiding',
					value: 'trainings',
				},
				{
					label: 'Evenement',
					value: 'events',
				},
			],
			value: entity,
			validations: [isRequired],
			isClearable: false,
			required: true,
			disabled: true,
		},
		important: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'important',
			label: 'Belangrijk',
			description: 'Markeer het bericht in het overzicht',
			value: object?.important ?? false,
		},
		pinned: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'pinned',
			label: 'Vastpinnen',
			description: 'Het bericht vastzetten aan de bovenkant van de feed',
			value: object?.pinned ?? false,
		},
		tags: {
			type: INPUT_TYPES.CREATABLE,
			name: 'tags',
			label: 'Tags',
			description:
				'Voeg een tag toe door hem in te typen en op enter te drukken',
			placeholder: 'Selecteer je tags',
			options: [],
			isCreatable: true,
			isMulti: true,
			isClearable: true,
			value: object?.tags ?? [],
		},
		organisers: {
			type: INPUT_TYPES.SELECT,
			name: 'organisers',
			label: 'Organisatoren',
			description: 'De organisatoren kunnen de inschrijvingen bekijken',
			placeholder: 'Selecteer de organisatoren',
			options: [],
			isMulti: true,
			isClearable: true,
			value: object?.organisers?.map(x => x.id) || [],
		},
		location: {
			type: INPUT_TYPES.CREATABLE,
			name: 'location',
			label: 'Locatie',
			description:
				'Voeg een locatie toe door ze in te typen en op enter te drukken. Komma spatie ", " zet de waarde in KetchUP op een volgende regel.',
			placeholder: 'Selecteer een locatie',
			options: [
				...locations,
				...(object?.location && !locations.includes(object?.location)
					? [{ label: object.location, value: object.location }]
					: []),
			],
			isCreatable: true,
			isClearable: true,
			value: object?.location ?? '',
			required: entity !== 'news',
			validations: entity === 'news' ? [] : [isRequired],
		},
		absence: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'absence',
			label: 'Kan opleidingsdagen gebruiken',
			value: false,
			disabled: true,
		},
		hours: {
			type: INPUT_TYPES.NUMBER,
			name: 'hours',
			label: 'Aantal uren',
			description:
				'Bovenstaande uren worden bij deelnemers geregistreerd voor elke dag volgens beschikbaarheid',
			validations: [isRequired],
			required: true,
		},
		is_added_to_cv: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'is_added_to_cv',
			label: 'Toegevoegd aan CV-UP',
			value: object?.isAddedToCV ?? false,
		},
		instructor: {
			type: INPUT_TYPES.SELECT,
			name: 'instructor',
			label: 'Lesgever',
			description: 'Voeg lesgevers eerst toe via het lesgeverspanneel',
			placeholder: 'Selecteer een lesgever',
			options: [],
			isClearable: true,
			value: object?.instructor?.id ?? null,
		},
		not_present_feedback: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'not_present_feedback',
			label: 'Vraag feedback bij afwezigheid',
			value: object?.notPresentFeedback ?? false,
		},
		form: {
			type: INPUT_TYPES.RADIO,
			name: 'form',
			label: 'Formulier',
			options: [
				{
					label: 'Geen formulier',
					value: 'none',
				},
				{
					label: 'Opleidingsformulier',
					value: 'training',
				},
				{
					label: 'Custom formulier',
					value: 'custom',
				},
			],
			value: entity === 'trainings' ? 'training' : 'none',
			required: true,
			validations: [isRequired],
			disabled: entity === 'trainings',
		},
		publish: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'publish',
			label: 'Het bericht publiceren',
			value: !!object?.publishedAt,
		},
		published_at: {
			type: INPUT_TYPES.DATEINPUT,
			name: 'published_at',
			label: 'Publiceren vanaf',
			description: 'Laat leeg om meteen te publiceren',
			value:
				object && object?.publishedAt
					? getDate(object?.publishedAt)
					: '',
		},
		publish_time: {
			type: INPUT_TYPES.TIME,
			name: 'publish_time',
			label: 'Tijd',
			icon: faClock,
			value:
				object && object?.publishedAt
					? getDate(object?.publishedAt, true)
					: '09:30',
		},
		urgent: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'urgent',
			label: 'Dringend',
			description: 'De nieuwsbrief zal meteen verzonden worden',
			value: false,
		},

		max_attendees: {
			type: INPUT_TYPES.NUMBER,
			name: 'max_invitees',
			label: 'Maximum aantal inschrijvingen',
			validations: entity === 'news' ? [] : [isLargerThan(1)],
			description: 'Laat leeg om geen limiet in te stellen',
			value: object?.maxAttendees ?? '',
		},
		max_attend_date: {
			type: INPUT_TYPES.DATEINPUT,
			name: 'max_registration_date',
			label: 'Maximale datum van inschrijving',
			description:
				'Wanneer leeg wordt de eerste dag van het event genomen',
			value:
				object && object?.maxAttendDate
					? getDate(object?.maxAttendDate)
					: '',
		},
		date: {
			type: INPUT_TYPES.DATEPICKER,
			name: 'date',
			label: 'Datum',
			mode: 'multiple',
			value: object?.days?.map(x => x.date) ?? [],
			required: entity !== 'news',
			validations: entity === 'news' ? [] : [isRequired],
		},
	};
};

export const entityLinkData = () => {
	return {
		name: {
			type: INPUT_TYPES.TEXT,
			name: 'name',
			label: 'Label',
			validations: [],
		},
		url: {
			type: INPUT_TYPES.TEXT,
			name: 'url',
			label: 'Url',
			required: true,
			validations: [isRequired, isValidUrl],
		},
	};
};

export const entityRegistrationData = (toppings, order) => {
	return {
		bread: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'bread',
			label: 'Ik neem geen broodje',
			value: order ? !order.topping : false,
		},
		topping: {
			type: INPUT_TYPES.SELECT,
			name: 'topping',
			label: 'Topping',
			placeholder: 'Selecteer een optie',
			options: toppings.map(x => ({ label: x.topping, value: x.id })),
			required: true,
			isClearable: false,
			validations: [isRequired],
			value: order?.topping || '',
		},
		size: {
			type: INPUT_TYPES.SELECT,
			name: 'size',
			label: 'Grootte',
			placeholder: 'Selecteer een optie',
			options: [
				{ value: 'large_white', label: 'Groot wit broodje' },
				{ value: 'large_dark', label: 'Groot bruin broodje' },
				{ value: 'small_white', label: 'Klein wit broodje' },
				{ value: 'small_dark', label: 'Klein bruin broodje' },
			],
			required: true,
			isClearable: false,
			validations: [isRequired],
			value: order?.size || '',
		},
		extra: {
			type: INPUT_TYPES.CHECKBOX,
			name: 'extra',
			label: 'Smos',
			value: order ? !!order.extra : false,
		},
	};
};
