import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadEntity, loadStatics } from 'core/store/entities/entity.actions';

import Card from 'components/Card/Card';
import DataTable from 'components/DataTable/DataTable';
import Graphic from 'components/Graphic/Graphic';
import { Row } from 'components/Grid';
import { AdminHeader } from 'components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import Content from 'components/Layouts/Content/Content';
import Sidebar from 'components/Layouts/Sidebar/Sidebar';
import Loading from 'components/Loading/Loading';

const EntityStatistics = props => {
	const dispatch = useDispatch();

	const [unconfirmed, setUnconfirmed] = useState(0);

	// eslint-disable-next-line no-unused-vars
	const types = [
		{
			name: 'intrest',
			title: 'Geen interesse het onderwerp',
			type: 'trainings',
		},
		{
			name: 'knowledge',
			title: 'Reeds voldoende kennis over het onderwerp',
			type: 'trainings',
		},
		{
			name: 'afterword',
			title: 'Liever geen evenementen of opleidingen na de werkuren',
			type: 'events',
		},
		{
			name: 'personal',
			title: 'Privé- of gezinsverplichtingen',
			type: 'events',
		},
		{ name: 'location', title: 'Locatie is te ver', type: 'events' },
		{
			name: 'method',
			title: 'Voorkeur voor andere leermethoden',
			type: 'trainings',
		},
		{ name: 'not_say', title: 'Ik zeg dit liever niet', type: 'events' },
	];

	const { id } = useParams();

	const { entitiesLoading, entity, statics } = useSelector(
		state => state.entityReducer,
	);

	useEffect(() => {
		dispatch(loadEntity(props.entity, id));
	}, [dispatch]);

	useEffect(() => {
		if (entity) dispatch(loadStatics('not-present', entity?.entryId));
	}, [dispatch, entity]);

	useEffect(() => {
		setUnconfirmed(entity?.invitees.filter(x => x.confirmed === 0).length);
	}, [entity]);

	return entitiesLoading ? (
		<Loading />
	) : (
		<div>
			<AdminHeader title='Statistiek' />

			<Row>
				<Content hasSidebar>
					<Card title='Indieningen' withShadow>
						<DataTable
							columns={[
								{
									name: 'lastName',
									label: 'Naam',
									className: '',
									sort: true,
								},
								{
									name: 'firstName',
									label: 'Voornaam',
									className: '',
									sort: true,
								},
								{
									name: 'feedback',
									label: 'Feedback',
									className: 'linebreak',
								},
							]}
							data={statics.map(x => ({
								...x,
								feedback: x.feedback
									.map(y => {
										const temp = types.find(
											type => type.name === y,
										);
										return temp.title;
									})
									.join('\n'),
							}))}
							paginate
							search
						/>
					</Card>
				</Content>

				<Sidebar>
					<Card title='Statistiek' withShadow>
						<Graphic
							type='bar'
							name='qty'
							title='Aantal ingevuld'
							data={[
								{
									base: unconfirmed,
									value: statics.length,
								},
							]}
							showLegend
						/>

						<Graphic
							type='bar'
							name='notPresent'
							title={`Statistiek op basis van ${statics.length} ${statics.length === 1 ? 'indiening' : 'indieningen'}`}
							description='Er zijn meerdere opties per gebruiker mogelijk.'
							data={types.map(item => ({
								label: item.title,
								base: statics.length,
								value: statics.filter(x =>
									x.feedback.includes(item.name),
								).length,
							}))}
							showLegend
						/>
					</Card>
				</Sidebar>
			</Row>
		</div>
	);
};

export default EntityStatistics;
