export const ACTION_TYPES = {
	AUTH_LOAD_PROFILE: 'AUTH_LOAD_PROFILE',
	AUTH_LOAD_PROFILE_COMMIT: 'AUTH_LOAD_PROFILE_COMMIT',
	AUTH_LOGOUT: 'AUTH_LOGOUT',
	AUTH_LOGOUT_COMMIT: 'AUTH_LOGOUT_COMMIT',

	THEME_MODE_UPDATE: 'THEME_MODE_UPDATE',
	THEME_UPDATE: 'THEME_UPDATE',
	THEME_UPDATE_COMMIT: 'THEME_UPDATE',

	AUTH_UPDATE_SETTINGS: 'AUTH_UPDATE_SETTINGS',
	AUTH_UPDATE_SETTINGS_COMMIT: 'AUTH_UPDATE_SETTINGS_COMMIT',

	MAILBOX_LOAD: 'MAILBOX_LOAD',
	MAILBOX_LOAD_COMMIT: 'MAILBOX_LOAD_COMMIT',
	MAILBOX_POST: 'MAILBOX_POST',
	MAILBOX_POST_COMMIT: 'MAILBOX_POST_COMMIT',

	LOAD_STATICS: 'LOAD_STATICS',
	LOAD_STATICS_COMMIT: 'LOAD_STATICS_COMMIT',
	LOAD_USER_STATICS: 'LOAD_USER_STATICS',
	LOAD_USER_STATICS_COMMIT: 'LOAD_USER_STATICS_COMMIT',
	LOAD_STATIC_DETAILS: 'LOAD_STATIC_DETAILS',
	LOAD_STATIC_DETAILS_COMMIT: 'LOAD_STATIC_DETAILS_COMMIT',

	TOAST_ADD: 'TOAST_ADD',
	TOAST_REMOVE: 'TOAST_REMOVE',

	TAGS_LOAD: 'TAGS_LOAD',
	TAGS_LOAD_COMMIT: 'TAGS_LOAD_COMMIT',
	TAGS_ADD: 'TAGS_ADD',

	COCES_LOAD: 'COCES_LOAD',
	COCES_LOAD_COMMIT: 'COCES_LOAD_COMMIT',

	SPORTS_LOAD: 'SPORTS_LOAD',
	SPORTS_LOAD_COMMIT: 'SPORTS_LOAD_COMMIT',

	DEPARTMENTS_LOAD: 'DEPARTMENTS_LOAD',
	DEPARTMENTS_LOAD_COMMIT: 'DEPARTMENTS_LOAD_COMMIT',

	TOPPINGS_LOAD: 'TOPPINGS_LOAD',
	TOPPINGS_LOAD_COMMIT: 'TOPPINGS_LOAD_COMMIT',

	PERMISSIONS_LOAD: 'PERMISSIONS_LOAD',
	PERMISSIONS_LOAD_COMMIT: 'PERMISSIONS_LOAD_COMMIT',
	PERMISSIONS_PUT: 'PERMISSIONS_PUT',
	PERMISSIONS_PUT_COMMIT: 'PERMISSIONS_PUT_COMMIT',

	ROLES_LOAD: 'ROLES_LOAD',
	ROLES_LOAD_COMMIT: 'ROLES_LOAD_COMMIT',
	ROLES_PUT: 'ROLES_PUT',
	ROLES_PUT_COMMIT: 'ROLES_PUT_COMMIT',

	EMPLOYEES_LOAD: 'EMPLOYEES_LOAD',
	EMPLOYEES_LOAD_COMMIT: 'EMPLOYEES_LOAD_COMMIT',

	ORGANISERS_LOAD: 'ORGANISERS_LOAD',
	ORGANISERS_LOAD_COMMIT: 'ORGANISERS_LOAD_COMMIT',

	INSTRUCTORS_LOAD: 'INSTRUCTORS_LOAD',
	INSTRUCTORS_LOAD_COMMIT: 'INSTRUCTORS_LOAD_COMMIT',
	INSTRUCTORS_POST: 'INSTRUCTORS_POST',
	INSTRUCTORS_POST_COMMIT: 'INSTRUCTORS_POST_COMMIT',
	INSTRUCTORS_PUT: 'INSTRUCTORS_PUT',
	INSTRUCTORS_PUT_COMMIT: 'INSTRUCTORS_PUT_COMMIT',
	INSTRUCTORS_DELETE: 'INSTRUCTORS_DELETE',
	INSTRUCTORS_DELETE_COMMIT: 'INSTRUCTORS_DELETE_COMMIT',

	ENTITIES_LOAD: 'ENTITIES_LOAD',
	ENTITIES_LOAD_COMMIT: 'ENTITIES_LOAD_COMMIT',
	ENTITY_LOAD: 'ENTITY_LOAD',
	ENTITY_LOAD_COMMIT: 'ENTITY_LOAD_COMMIT',
	ENTITY_FORM_LOAD: 'ENTITY_FORM_LOAD',
	ENTITY_FORM_LOAD_COMMIT: 'ENTITY_FORM_LOAD_COMMIT',
	ENTITY_POST: 'ENTITY_POST',
	ENTITY_POST_COMMIT: 'ENTITY_POST_COMMIT',
	ENTITY_PUT: 'ENTITY_PUT',
	ENTITY_PUT_COMMIT: 'ENTITY_PUT_COMMIT',
	ENTITY_DELETE: 'ENTITY_DELETE',
	ENTITY_DELETE_COMMIT: 'ENTITY_DELETE_COMMIT',
	ENTITY_LOAD_REGISTRATIONS: 'ENTITY_LOAD_REGISTRATIONS',
	ENTITY_LOAD_REGISTRATIONS_COMMIT: 'ENTITY_LOAD_REGISTRATIONS_COMMIT',
	ENTITY_POST_REGISTRATIONS: 'ENTITY_POST_REGISTRATIONS',
	ENTITY_POST_REGISTRATIONS_COMMIT: 'ENTITY_POST_REGISTRATIONS_COMMIT',
	ENTITY_POST_SUBSCRIPTION: 'ENTITY_POST_SUBSCRIPTION',
	ENTITY_POST_SUBSCRIPTION_COMMIT: 'ENTITY_POST_SUBSCRIPTION_COMMIT',
	ENTITY_PUT_ATTENDANCY: 'ENTITY_PUT_ATTENDANCY',
	ENTITY_LOAD_STATICS: 'ENTITY_LOAD_STATICS',
	ENTITY_LOAD_STATICS_COMMIT: 'ENTITY_LOAD_STATICS_COMMIT',
	ENTITY_PUT_ATTENDANCY_COMMIT: 'ENTITY_PUT_ATTENDANCY_COMMIT',

	WELL_BEINGS_LOAD: 'WELL_BEINGS_LOAD',
	WELL_BEINGS_LOAD_COMMIT: 'WELL_BEINGS_LOAD_COMMIT',
	WELL_BEING_LOAD: 'WELL_BEING_LOAD',
	WELL_BEING_LOAD_COMMIT: 'WELL_BEING_LOAD_COMMIT',
	WELL_BEING_POST: 'WELL_BEING_POST',
	WELL_BEING_POST_COMMIT: 'WELL_BEING_POST_COMMIT',
	WELL_BEING_PUT: 'WELL_BEING_PUT',
	WELL_BEING_PUT_COMMIT: 'WELL_BEING_PUT_COMMIT',
	WELL_BEING_DELETE: 'WELL_BEING_DELETE',
	WELL_BEING_DELETE_COMMIT: 'WELL_BEING_DELETE_COMMIT',
	WELL_BEING_LOAD_REGISTRATIONS: 'WELL_BEING_LOAD_REGISTRATIONS',
	WELL_BEING_LOAD_REGISTRATIONS_COMMIT:
		'WELL_BEING_LOAD_REGISTRATIONS_COMMIT',

	MOVING_MISSIONS_LOAD: 'MOVING_MISSIONS_LOAD',
	MOVING_MISSIONS_LOAD_COMMIT: 'MOVING_MISSIONS_LOAD_COMMIT',
	MOVING_MISSION_LOAD: 'MOVING_MISSION_LOAD',
	MOVING_MISSION_LOAD_COMMIT: 'MOVING_MISSION_LOAD_COMMIT',
	MOVING_MISSION_POST: 'MOVING_MISSION_POST',
	MOVING_MISSION_POST_COMMIT: 'MOVING_MISSION_POST_COMMIT',
	MOVING_MISSION_DELETE: 'MOVING_MISSION_DELETE',
	MOVING_MISSION_DELETE_COMMIT: 'MOVING_MISSION_DELETE_COMMIT',
	MOVING_MISSION_LOAD_REGISTRATIONS: 'MOVING_MISSION_LOAD_REGISTRATIONS',
	MOVING_MISSION_LOAD_REGISTRATIONS_COMMIT:
		'MOVING_MISSION_LOAD_REGISTRATIONS_COMMIT',
	MOVING_MISSION_LOAD_DETAIL: 'MOVING_MISSION_LOAD_DETAIL',
	MOVING_MISSION_LOAD_DETAIL_COMMIT: 'MOVING_MISSION_LOAD_DETAIL_COMMIT',
};
