import {
	arrayOf,
	bool,
	number,
	object,
	oneOfType,
	shape,
	string,
} from 'prop-types';

import Bar from './Bar/Bar';
import Pie from './Pie/Pie';

import './Graphic.scss';

const Graphic = ({
	type,
	name,
	title = null,
	data,
	description = null,
	showLegend = false,
	singleGraph = false,
}) => {
	let component;

	switch (type) {
		case 'bar':
			component = (
				<Bar
					data={data}
					showLegend={showLegend}
					name={name}
					singleGraph={singleGraph}
					description={description}
				/>
			);
			break;
		case 'pie':
			component = (
				<Pie
					data={data}
					showLegend={showLegend}
					description={description}
				/>
			);
			break;
	}

	return (
		<div className='graphic'>
			{title ? <h3 className='h4'>{title}</h3> : ''}
			{component}
		</div>
	);
};

Graphic.propTypes = {
	type: string.isRequired,
	name: string.isRequired,
	title: string,
	data: arrayOf(
		shape({
			base: number.isRequired,
			value: number.isRequired,
			label: oneOfType([string, object]),
			className: string,
		}),
	).isRequired,
	description: string,
	showLegend: bool,
	singleGraph: bool,
};

export default Graphic;
